<template>
  <v-container
    style="overflow: hidden"
    fluid
    class="fill-height ma-0 pa-0 w500 login"
  >
    <div class="row fill-height">
      <div class="col-12 col-md-6 bg-image hidden-sm-and-down">
        <div class="d-flex fill-height justify-center align-center">
          <img
            width="50%"
            src="../assets/img/logo-white.svg"
            alt=""
          >
        </div>
      </div>
      <div class="col-12 col-md-6 d-flex align-center justify-center">
        <div class="flex md7 xs11 card">
          <div class="card-titulo mb-10">
            {{ $t('login.faca_login') }}
          </div>

          <p
            v-if="mensagemErro"
            class="red--text"
          >
            {{ mensagemErro }}
          </p>

          <v-form class="row">
            <input-text
              v-model="form.login"
              :disabled="carregando"
              class="col-12"
              :label="$t('login.login')"
              obrigatorio
              @enter="autenticar"
            >
              <template #prepend-inner>
                <v-icon> $email </v-icon>
                <v-divider
                  vertical
                  class="mx-2"
                />
              </template>
            </input-text>
            <input-text
              v-model="form.password"
              :disabled="carregando"
              class="col-12"
              :label="$t('login.senha')"
              obrigatorio
              type="password"
              @enter="autenticar"
            >
              <template #prepend-inner>
                <v-icon> $lock </v-icon>
                <v-divider
                  vertical
                  class="mx-2"
                />
              </template>
            </input-text>
          </v-form>

          <botao-padrao
            class="mt-6 botao-padrao-fill"
            :carregando="carregando"
            @click="autenticar"
          >
            {{ $t('login.acessar') }}
          </botao-padrao>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
// import ColaboradorService from '@/common/services/cadastros/ColaboradorService';
// import ParametroService from '@/common/services/cadastros/ParametroService';
// import UsuarioService from '@/common/services/cadastros/UsuarioService';
import AutenticacaoService from '@common/services/AutenticacaoService';
// import ColaboradorAusenciaService from '@common/services/cadastros/ColaboradorAusenciaService';
// import moment from 'moment';
export default {
  data() {
    return {
      form: {
        login: '',
        password: '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      carregando: false,
      mensagemErro: '',
      ipUsuario: null,
      datas: [],
      feriados: [],
    };
  },
  computed: {
    acessarHabilitado: function () {
      return this.form.login && this.form.password;
    },
  },
  mounted() {
    this.buscarIpUsuario();

    this.$store.dispatch('Layout/alterarTituloPagina', 'Login');
    this.$store.dispatch('Layout/terminarCarregamento');
  },
  methods: {
    autenticar: async function () {
      if (!this.acessarHabilitado) return;
      this.carregando = true;
      const enderecoIP = await fetch('https://api.ipify.org?format=json')
        .then((res) => res.json())
        .then((data) => data.ip);

      let auth;
      await AutenticacaoService.autenticar({...this.form, enderecoIP})
        .then((res) => {
          auth = res;
        })
      .catch((erro) => {
        this.carregando = false;
        if (erro?.response?.data.errors !== undefined)
          this.mensagemErro = this.$t(
            `geral.erros.${erro.response.data.errors[0]}`
          );
      });

      if (!auth) return;
      const encoded = JSON.parse(atob(auth.data.token.split('.')[1]));

      localStorage.setItem(
        'participanteLogadoId',
        encoded.participante_logado_id
      );
      localStorage.setItem('participanteContaId', encoded.conta_id);
      localStorage.setItem('usuarioId', auth.data.usuario?.id);
      localStorage.setItem('usuarioSetorId', auth.data.usuario?.setorId);
      localStorage.setItem('usuarioNome', auth.data.usuario?.nome);
      localStorage.setItem('flagAtivoUsuario', auth.data.usuario?.flagAtivo);

      this.$store.dispatch('Autenticacao/autenticar', {
        tokenData: auth.data,
        encodedData: encoded,
      });

      if (this.$route?.params?.redirect)
        await this.$router.push(this.$route.params.redirect);
      else await this.$router.push('/dashboard');

      // const userRes = await UsuarioService.buscar(auth.data.usuario?.id);
      // const usuario = userRes.data;

      // if (!usuario.colaborador) {
      //   this.$store.dispatch('Autenticacao/alterarColaborador', {
      //     colaborador: null,
      //   });
      // } else {
      //   try {
      //     const colabRes = await ColaboradorService.buscar(
      //       usuario.colaborador.id
      //     );

      //     this.$store.dispatch('Autenticacao/alterarColaborador', {
      //       colaborador: colabRes.data,
      //     });
      //   } catch (erro) {
      //     if (erro?.response?.data.errors !== undefined)
      //       this.mensagemErro = this.$t(
      //         `geral.erros.${erro.response.data.errors[0]}`
      //       );
      //   }
      // }
      // const colaborador = this.$store.state.Autenticacao.colaborador;

      // if (colaborador !== null) {
      //   const ausenciaRes = await ColaboradorAusenciaService.listar({
      //     Page: 1,
      //     AmountPerPage: 999,
      //     colaboradorId: colaborador.id,
      //   });

      //   if (ausenciaRes.data.items.length > 0) {
      //     if (this.verificarAusenciaProgramada(colaborador, ausenciaRes.data)) {
      //       this.toastErro(
      //         this.$t(`geral.erros.restricao_por_ausencia_programada`)
      //       );
      //       houveErro = true;
      //     }
      //   }

      //   //return
      // }
      // const parametroRes = await ParametroService.buscar();
      // const parametroConta = parametroRes.data;
      // const temFimDeSemana =
      //   new Date().getDay() === 0 || new Date().getDay() === 6;

      // const temFeriado = this.datas.some((data) => {
      //   return this.feriados.some((feriado) => {
      //     return moment(feriado, 'YYYY-MM-DD').isSame(
      //       moment(data, 'YYYY-MM-DD')
      //     );
      //   });
      // });

      // if (colaborador && parametroConta.flagConsiderarDiasUteisPrazoEntrega) {
      //   if (temFimDeSemana || temFeriado) {
      //     this.toastErro(this.$t(`geral.erros.restricao_por_horario_trabalho`));
      //     houveErro = true;

      //     //return;
      //   }
      // }
      // const horaAtual = moment();

      // if (colaborador && colaborador.restringirAcessoPorHorarioTrabalho) {
      //   const estaNaManha = horaAtual.isBetween(
      //     moment(colaborador.horarioInicioTrabalho, 'hh:mm A'),
      //     moment(colaborador.horarioInicioIntervalo, 'hh:mm A')
      //   );
      //   const estaNaTarde = horaAtual.isBetween(
      //     moment(colaborador.horarioTerminoIntervalo, 'hh:mm A'),
      //     moment(colaborador.horarioTerminoTrabalho, 'hh:mm A')
      //   );

      //   if (!estaNaManha && !estaNaTarde) {
      //     this.toastErro(this.$t(`geral.erros.restricao_por_horario_trabalho`));
      //     houveErro = true;
      //   }
      // }

      // if (colaborador && colaborador.restringirAcessoPorIP) {
      //   auth.data.usuario?.parametroGeralEnderecosIPs.forEach((ip) => {
      //     if (
      //       ip.enderecoIPInicial !== this.ipUsuario ||
      //       ip.enderecoIPFinal !== this.ipUsuario
      //     ) {
      //       this.toastErro(this.$t(`geral.erros.restricao_por_ip`));

      //       houveErro = true;
      //       // return;
      //     }
      //   });
      // }

      // if (!houveErro && this.ipUsuario){
      //   await UsuarioService.alterarEnderecoIp(usuario.id, {
      //     enderecoIP: this.ipUsuario,
      //   });
      // }

      // if (!houveErro) {
      //   if (this.$route?.params?.redirect) await this.$router.push(this.$route.params.redirect);
      //   else await this.$router.push('/dashboard');
      // }

      // this.carregando = false;
    },
    buscarIpUsuario: async function () {
      const response = await fetch('https://api.ipify.org?format=json');
      if (response.status !== 200 || !this.ipUsuario)
        setTimeout(this.buscarIpUsuario, 1000);
      const data = await response?.json();
      this.ipUsuario = data.ip;
    },
    verificarAusenciaProgramada: function (
      ehColaborador,
      possuiAusenciaProgramada
    ) {
      if (ehColaborador && possuiAusenciaProgramada) {
        const ehAusenciaPogramada = possuiAusenciaProgramada.items.some(
          (ausencia) => {
            return (
              ausencia.dataInicial <= new Date() &&
              ausencia.dataFinal >= new Date()
            );
          }
        );

        if (ehAusenciaPogramada) return;
      }
      return false;
    },
    verificarFimDeSemanaFeriado: function (
      ehColaborador,
      flagDiasUteis,
      temFimDeSemana,
      temFeriado
    ) {
      if (ehColaborador && flagDiasUteis) {
        if (temFimDeSemana || temFeriado) {
          this.toastErro(this.$t(`geral.erros.restricao_por_horario_trabalho`));
          return true;
        }
      }
      return false;
    },
    converterHorasMinutos: function (data) {
      const [horas, minutos] = data.split(':').map(Number);
      return horas * 60 + minutos;
    },
  },
};
</script>
<style lang="scss">
.login {
  .card {
    color: $color-primary-grey-900;

    &-titulo {
      font-size: 36px;
      line-height: 48px;
    }

    &-subtitulo {
      color: $color-primary-grey-500;
    }
  }
  .bg-image {
    background: #13539b;
    background: linear-gradient(90deg, #13539b 0%, #1659a0 49%, #528eca 100%);
  }
  .botao-padrao {
    text-transform: uppercase !important;
    .v-btn__content {
      justify-content: center;
    }
  }
}
</style>
